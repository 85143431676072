import React, { useState, useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'
import Button from '../Button'
import StyledLabel from '../Common/StyledLabel'
import useSectorListQuery from '../../hooks/use-sector-list-query'
import FilterIcon from '../FilterIcon'
import clsx from 'clsx'
import {
	modal,
	modalHeader,
	modalClose,
	modalBody,
	cta,
	ctaCount,
	filterSelect,
	filterSelectIcon,
	filterSelectIconLocation,
	filterSelectIconLanguage,
	filterSelectIconService,
	filterSelectIconSector,
	filterIcon,
} from './Filters.module.scss'

const Filters = ({ ...props }) => {
	const { languages, offices, setFilterState, filterState } = props

	const services = useSectorListQuery('service')
	const sectors = useSectorListQuery('sector')

	const [show, setShow] = useState(false)
	const [officeFilter, setOfficeFilter] = useState(filterState?.office)
	const [languageFilter, setLanguageFilter] = useState(filterState?.language)
	const [serviceFilter, setServiceFilter] = useState(filterState?.service)
	const [sectorFilter, setSectorFilter] = useState(filterState?.sector)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const handleOfficeChange = e => setOfficeFilter(e.target.value)
	const handleLanguageChange = e => setLanguageFilter(e.target.value)
	const handleServiceChange = e => setServiceFilter(e.target.value)
	const handleSectorChange = e => setSectorFilter(e.target.value)

	const handleSubmit = e => {
		e.preventDefault()
		setFilterState({
			office: officeFilter,
			language: languageFilter,
			service: serviceFilter,
			sector: sectorFilter,
		})
		handleClose()
	}

	useEffect(() => {
		setOfficeFilter(filterState?.office)
		setLanguageFilter(filterState?.language)
		setServiceFilter(filterState?.service)
		setSectorFilter(filterState?.sector)
	}, [filterState])

	const buttonText = (
		<>
			Filter <FilterIcon className={filterIcon} />
		</>
	)

	return (
		<>
			<div className={cta}>
				<Button
					theme="gray"
					onClick={handleShow}
					buttonText={buttonText}
					size="small"
				/>
				{!!props.filterCount && (
					<span className={ctaCount}>{props.filterCount}</span>
				)}
			</div>

			<Modal
				show={show}
				onHide={handleClose}
				contentClassName={modal}
				aria-labelledby="people-filter-modal"
			>
				<Modal.Header className={modalHeader}>
					<Modal.Title>
						<div className="d-flex align-items-center">
							<StyledLabel round variant="filter" label="" size="small" />
							<h3 className="mb-0" id="people-filter-modal">
								Filter Results
							</h3>
						</div>
					</Modal.Title>
					<button onClick={handleClose} className={modalClose} type="button">
						<span className="screen-reader-text">Close</span>
						<i className="ss-icon ss-black-tie-bold ss-delete" />
					</button>
				</Modal.Header>
				<Modal.Body className={modalBody}>
					<Form onSubmit={handleSubmit}>
						{offices && (
							<Form.Group
								controlId="peopleFilter.Offices"
								className={clsx(filterSelectIcon, filterSelectIconLocation)}
							>
								<Form.Label visuallyHidden={true}>Find People by Office</Form.Label>
								<Form.Select
									className={filterSelect}
									onChange={handleOfficeChange}
									defaultValue={filterState.office}
								>
									<option value="">Location</option>
									{offices.map(({ office }) => (
										<option value={office.slug} key={`office-${office.slug}`}>
											{office.title}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						)}

						{services && (
							<Form.Group
								controlId="peopleFilter.Services"
								className={clsx(filterSelectIcon, filterSelectIconService)}
							>
								<Form.Label visuallyHidden={true}>Find People by Services</Form.Label>
								<Form.Select
									className={filterSelect}
									onChange={handleServiceChange}
									defaultValue={filterState.service}
								>
									<option value="">Services</option>
									{services.map(({ sector }) => (
										<option value={sector.slug} key={`service-${sector.slug}`}>
											{sector.title}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						)}

						{sectors && (
							<Form.Group
								controlId="peopleFilter.Sectors"
								className={clsx(filterSelectIcon, filterSelectIconSector)}
							>
								<Form.Label visuallyHidden={true}>Find People by Sector</Form.Label>
								<Form.Select
									className={filterSelect}
									onChange={handleSectorChange}
									defaultValue={filterState.sector}
								>
									<option value="">Sectors</option>
									{sectors.map(({ sector }) => (
										<option value={sector.slug} key={`sector-${sector.slug}`}>
											{sector.title}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						)}

						{languages && (
							<Form.Group
								controlId="peopleFilter.Languages"
								className={clsx(filterSelectIcon, filterSelectIconLanguage)}
							>
								<Form.Label visuallyHidden={true}>Find People by Language</Form.Label>
								<Form.Select
									className={filterSelect}
									onChange={handleLanguageChange}
									defaultValue={filterState.language}
								>
									<option value="">Language</option>
									{languages.map(({ language }) => {
										return language.allpeople.nodes.length ? (
											<option
												value={language.slug}
												key={`language-${language.slug}`}
											>
												{language.name}
											</option>
										) : null
									})}
								</Form.Select>
							</Form.Group>
						)}

						<div className="text-end mt-4">
							<Button
								onClick={handleSubmit}
								buttonText="Apply Filters"
								theme="gray"
								size="small"
							/>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Filters
