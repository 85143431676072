import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import clsx from 'clsx'
import BtnLink from '../../Common/StyledButton/BtnLink'
import {
	content,
	cta,
	author,
	modalLoadBtn,
	modalContainer,
	modalClose,
	modalContentCopy,
	modalAuthorTitle,
} from './ModalContent.module.scss'

const ModalContent = ({ ...props }) => {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	return (
		<>
			<span className={modalLoadBtn} onClick={handleShow}>
				<BtnLink
					cta={props.cta}
					variant={props.type}
					useButton={true}
				/>
			</span>

			<Modal
				fullscreen="sm-down"
				contentClassName="modal-wrapper"
				size="xl"
				show={show}
				onHide={handleClose}
				dialogClassName={clsx('modal-90w', modalContainer)}
				aria-labelledby="example-custom-modal-styling-title"
			>
				<ModalHeader bsPrefix="modal-header-custom">
					<button onClick={handleClose} className={clsx('close', modalClose)}>
						Close
					</button>
					<Row>
						<Col xs={12} sm={8} md={9} lg={8}>
							<Modal.Title>
								{props.text ? (
									<div
										className={clsx('editor-section', content)}
										dangerouslySetInnerHTML={{ __html: props.text }}
									/>
								) : null}
							</Modal.Title>
						</Col>
					</Row>
				</ModalHeader>
				<Row>
					<Col xs={12} sm={8} md={9}>
						<hr />
					</Col>
				</Row>

				<Modal.Body>
					<Row>
						<Col xs={12} sm={8} md={10} lg={8}>
							{props.text ? (
								<div
									className={clsx('editor-section', modalContentCopy)}
									dangerouslySetInnerHTML={{ __html: props.fullText }}
								/>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={8} md={12} lg={8}>
							<div className={author}>
								{props.author ? (
									<span className="title--sm">{props.author}</span>
								) : null}

								<br />
								{props.jobtitle ? (
									<span className={clsx('label-small', modalAuthorTitle)}>
										{props.jobtitle}
									</span>
								) : null}
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="p-0" xs={12} sm={8} md={12} lg={8}>
							<div className={cta}>
								<BtnLink cta="see more like this" />
							</div>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ModalContent
