import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Masonry from 'react-masonry-component';
import Container from 'react-bootstrap/Container'
import clsx from 'clsx'
import ModalContent from '../ModalContent'
import {Col, Form, Row} from "react-bootstrap";
import SearchInput from "../../SearchInput";
import Filters from "../../Filters";
import BtnLink from "../../Common/StyledButton/BtnLink";
import LegalDisclaimer from '../LegalDisclaimer'
import {
	testimonialGrid,
	testimonialGridHeader,
	testimonialGridForm,
	testimonialGridContainer,
	cta,
	gridSizer,
	gridItem,
	gridItemInner,
	large,
	shadow,
	content,
	contentContainer,
	authorContainer,
	authorTitle,
	authorJobTitle,
	disclaimerWrapper
} from './TestimonialGrid.module.scss'

const Testimonial = styled.div`
	background: transparent;
	color: ${props => (props.variant === 'white' ? '#333333' : '#fff')};
`

const TestimonialGrid = ({ ...props }) => {
	if (typeof window === 'undefined') {
		return null
	}
	const {headline, includeSearch, testimonial} = props
	const fullTestimonialList = testimonial
	const [testimonials, setTestimonials] = useState(testimonial)

	// search and filter setup
	const loaded = useRef(false);
	const searchInput = useRef(false)
	const [searchTerm, setSearchTerm] = useState(null)
	const [filtered, setFiltered] = useState(false)
	const [searched, setSearched] = useState(!!searchTerm)
	const [filterState, setFilterState] = useState({
		service: null,
		sector: null,
	})

	const handleClearFilter = e => {
		setSearchTerm(null)
		searchInput.current.value = ''
		setFilterState({
			service: null,
			sector: null,
		})
	}

	useEffect(() => {
		// if page is loaded with a search term
		if (!loaded.current) {
			if (searchTerm) {
				handleSubmit()
			}
		}
	}, [testimonials, filterState]);

	useEffect(() => {
		filterResults()
	}, [filterState])

	const filterResults = () => {
		// prevent double filtering on page load
		if (!loaded.current) {
			loaded.current = true;
			return
		}

		let testimonialsList = fullTestimonialList
		let sectorTestimonials = []
		let serviceTestimonials = []

		if (filterState.sector) {
			sectorTestimonials = testimonialsList?.filter((testimonial ) => testimonial?.testimonialInformation?.proService?.map(service => service?.slug).includes(filterState.sector))
		}

		if (filterState.service) {
			serviceTestimonials = testimonialsList?.filter((testimonial ) => testimonial?.testimonialInformation?.proService?.map(service => service?.slug).includes(filterState.service))
		}

		if (filterState.sector || filterState.service) {
			testimonialsList = [...sectorTestimonials, ...serviceTestimonials]

			testimonialsList = new Set(testimonialsList)
			testimonialsList = Array.from(testimonialsList)
		}

		// no filters and no search terms, just reset
		if (!filterState.service && !filterState.sector && !searchTerm) {
			search(fullTestimonialList)
			setFiltered(false)
			setSearched(false)
			return
		}

		search(testimonialsList)
		setFiltered(true)
	}

	const handleInputChange = e => {
		setSearchTerm(e.target.value)
	}

	const handleSubmit = e => {
		e?.preventDefault();
		filterResults()
	}

	const search = (testimoniaList) => {
		if (!searchTerm) {
			setTestimonials(testimoniaList)
			setSearched(false)
			return
		}
		const res = testimoniaList.filter((testimonial) => {
			const term = searchTerm.toLowerCase()
			const content = testimonial.content.toLowerCase()
			const author = testimonial.testimonialInformation.author.toLowerCase()
			const fullText = testimonial.testimonialInformation.testimonialfull.toLowerCase()
			const service = testimonial?.testimonialInformation?.proService?.map(service => service?.title).join(' ').toLowerCase()

			return content?.includes(term) || author?.includes(term) || fullText?.includes(term) || service?.includes(term)
		});

		setTestimonials(res)
		setSearched(true)
	}

	const filterCount = Object.values(filterState).filter(x => x !== '' && x !== null && typeof(x) !== 'undefined').length

	console.log(filtered, searched, filtered || searched);
	return (
		<div className={testimonialGrid}>
			{headline && (
			<header className={testimonialGridHeader}>
				<Container>
					<Row>
						<Col className=" mb-3 mb-sm-0" xs={12} sm={6} lg={5}>
							<h1 className="title--xl title--decor" dangerouslySetInnerHTML={{__html: headline}} />
						</Col>
						{includeSearch && (
							<Col xs={12} sm={6} lg={{span: 5, offset: 2}}>
								<div className="d-flex justify-content-end">
									<div className="w-100">
										<Form onSubmit={handleSubmit} className={testimonialGridForm}>
											<SearchInput
												defaultValue={searchTerm}
												value={searchTerm}
												placeholderText="Search testimonials"
												aria-label="Search Testimonials"
												onInputChange={handleInputChange}
												size="sm"
												iconSize='sm'
												onBtnClick={handleSubmit}
												innerRef={searchInput}
											/>
										</Form>
									</div>
									<div className="d-none d-md-block">
										<Filters
											setFilterState={setFilterState}
											filterState={filterState}
											filterCount={filterCount}
										/>
									</div>
								</div>
								{(filtered || searched) && (
									<div className="mt-2 px-3 justify-content-end d-flex">
										<BtnLink onClick={handleClearFilter} useButton={true} cta="Clear Filters" />
									</div>
								)}
							</Col>
						)}
					</Row>
				</Container>
			</header>
			)}

			<div className={testimonialGridContainer}>
				<Container>
					<Masonry
						options={{
							columnWidth: `.${gridSizer}`,
							gutter: 30,
						}}
					>
					  <div className={[gridSizer, 'isotope-grid-sizer'].join(' ')}></div>
						{testimonials.map((item, index) => {
							const testimonial = item.hasOwnProperty('testimonial') ? item.testimonial : item
							const info = testimonial?.testimonialInformation
							const { variant, size, author, jobtitle, testimonialfull } = info

							return (
							<Testimonial
									key={index}
									variant={variant}
								className={clsx(
									size === 'large' && large,
										gridItem,
									'isotope-grid-item'
								)}
								>
									<div className={shadow} />
									<div
									className={clsx(
												gridItemInner,
												`bg-${variant}`,
									)}
									>
									<div className={contentContainer}>
											{testimonial.content ? (
												<div
												className={clsx('editor-section', content)}
													dangerouslySetInnerHTML={{ __html: testimonial.content }}
												/>
											) : null}

										<div className={authorContainer}>
												{author ? (
												<h2
													className={clsx(
														variant !== 'white' && 'text-white',
														authorTitle
													)}
												>
													{author}
												</h2>
												) : null}

												{jobtitle ? (
												<h3
													className={clsx(
														variant !== 'white' && 'text-white',
														authorJobTitle
													)}
												>
													{jobtitle}
												</h3>
												) : null}
											</div>
										</div>

										<div className={cta}>
											<hr />
										<div>
											<ModalContent
												text={testimonial.content}
												fullText={testimonialfull}
												cta="See Full Quote"
												type={
													variant === 'primary'
														? 'white'
														: variant === 'secondary'
														? 'secondary'
														: ''
												}
												author={author}
												jobtitle={jobtitle}
											/>
										</div>
									</div>
								</div>
							</Testimonial>
							)
						})}
					</Masonry>
					{props.disclaimerContent && 
						<div className={disclaimerWrapper}>
								<LegalDisclaimer           
								headline={props.disclaimerHeadline || ''} disclaimer={props.disclaimerContent}/>
						</div>}
				</Container>
			</div>
		</div>
	)
}

export default TestimonialGrid
