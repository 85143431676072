import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {
	disclaimerWrapper,
	disclaimerHeadline,
	disclaimerContent,
	contentWrapper,
} from './LegalDisclaimer.module.scss'

const LegalDisclaimer = ({ ...props }) => {
	return (
		<div className={disclaimerWrapper}>
			<Container>
				<Row>
					<Col className="align-content-center">
						<div className={contentWrapper}>
							<h4 className={disclaimerHeadline}>{props.headline}</h4>
							<p className={disclaimerContent}>{props.disclaimer}</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default LegalDisclaimer
