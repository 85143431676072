import React from 'react'
import clsx from "clsx";
import { filterIcon, filterIconDark, filterIconWhite } from './FilterIcon.module.scss'

const FilterIcon = ({className, color, ...props}) => {
  return (
    <span className={clsx(
      filterIcon,
      className,
      color === 'dark' ? filterIconDark : filterIconWhite,
    )} {...props}>
      <span />
      <span />
    </span>
  )
}

export default FilterIcon