import React from 'react'
import {GatsbyImage, StaticImage, getImage} from 'gatsby-plugin-image'
import clsx from "clsx";
import Link from '../Link'
import IconLink from '../Common/StyledButton/IconLink'
import {
	peopleCard,
	headshot,
	headshotImg,
	leaderLabel,
	heading,
	details,
	cta,
	phone,
} from './PeopleCard.module.scss'
import IconBtn from '../Common/StyledButton/IconBtn'


const PeopleCard = ({ people, ...props }) => {
	const showContact = !props.hideContact
	const featuredImage =
		people?.featuredImage?.node?.localFile?.childImageSharp
			?.gatsbyImageData
	let image = featuredImage ? getImage(featuredImage) : null
	let leaderText = props.coLeader ? 'Co-Leader' : props.leader ? 'Leader' : null

	return (
		<div className={peopleCard}>
			<div className={headshot}>
				<Link to={people?.uri} aria-label={people?.title}  state={{activeComponent: 'PeopleGridView', path: props.path}}>
					{leaderText ? (
						<span className={leaderLabel}>{leaderText}</span>
					) : null}
					{image ? (
						<GatsbyImage
							image={image}
							alt={people.title}
							className={headshotImg}
						/>
					) : (
						<StaticImage
							src="../../images/logo-placeholder.png"
							alt={people.title}
							width={300}
							height={345}
							className={headshotImg}
						/>
					)}
				</Link>
			</div>

			<div className={heading}>
				<Link to={people?.uri}  state={{activeComponent: 'PeopleGridView', path: props.path}}>
					<h2 className="title--md">{`${people?.peopleInformation?.lastName}, ${people?.peopleInformation?.firstName}`}</h2>
				</Link>
			</div>

			<div className={details}>
				<p className="label-small text-uppercase">
					{people?.jobTitles?.nodes ? (
						<span>
							{people?.jobTitles?.nodes.map((job, i, arr) => (
								<span key={job.id}>
									{job.name}
									{arr.length > i + 1 ? <span className="mx-1">&</span> : null}
								</span>
							))}
						</span>
					) : null}

					{people?.peopleInformation?.proOffice ? (
						<span>
							<span> • </span>
							{people?.peopleInformation?.proOffice[0]?.officeInformation?.proOfficeCity?.trim()}
							, {people?.peopleInformation?.proOffice[0]?.officeInformation?.proOfficeState}
						</span>
					) : null}
				</p>
			</div>

			{showContact ? (
				<div className={clsx(cta, 'd-none d-md-flex')}>
					{people?.peopleInformation?.email && (
						<IconBtn href={`mailto:${people?.peopleInformation?.email}`} variant="SendEmail"/>
					)}

					<div className={phone}>
						<span className="title--xs">
							{people?.peopleInformation?.phone ? (
								<Link href={'tel:' + people?.peopleInformation?.phone}>
									{people?.peopleInformation?.phone}
								</Link>
							) : null}
						</span>
					</div>
				</div>
			) : (
				<IconLink to={people?.uri} variant="ViewProfile" />
			)}
		</div>
	)
}
export default PeopleCard
