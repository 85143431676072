// extracted by mini-css-extract-plugin
export var testimonialGrid = "TestimonialGrid-module--testimonial-grid--MsAcm";
export var testimonialGridHeader = "TestimonialGrid-module--testimonial-grid-header--kssqV";
export var testimonialGridForm = "TestimonialGrid-module--testimonial-grid-form--2yuU4";
export var testimonialGridContainer = "TestimonialGrid-module--testimonial-grid-container--YKPmE";
export var disclaimerWrapper = "TestimonialGrid-module--disclaimer-wrapper--3VnEN";
export var cta = "TestimonialGrid-module--cta--36yMq";
export var contentAuthorWrapper = "TestimonialGrid-module--contentAuthorWrapper--Rex-C";
export var content = "TestimonialGrid-module--content--1b7gE";
export var gridSizer = "TestimonialGrid-module--grid-sizer--132-n";
export var gridItem = "TestimonialGrid-module--grid-item--BAkIy";
export var shadow = "TestimonialGrid-module--shadow--3RCVb";
export var gridItemInner = "TestimonialGrid-module--grid-item-inner--3YLo-";
export var contentContainer = "TestimonialGrid-module--contentContainer--2iVlh";
export var authorContainer = "TestimonialGrid-module--author-container--2n7TY";
export var authorTitle = "TestimonialGrid-module--author-title--95Rli";
export var authorJobTitle = "TestimonialGrid-module--author-job-title--1I3rN";
export var large = "TestimonialGrid-module--large--3Vu0K";